import React from "react";
import * as Formik from "formik";
import { SelectField } from "./SelectField";
import { Column } from "../../helpers/layout";

export function BlockHrField<T>(props: { name: string }) {
  return (
    <Formik.FastField
      name={props.name}
      render={() => {
        return (
          <Column>
            <SelectField
              fullWidth
              label="Line Size"
              name={`${props.name}.size`}
              options={[
                {
                  value: "small",
                  label: "Small"
                },
                {
                  value: "medium",
                  label: "Medium"
                },
                {
                  value: "large",
                  label: "Large"
                },
                {
                  value: "extra-large",
                  label: "Extra-large"
                },
                {
                  value: "full",
                  label: "Fullsize"
                }
              ]}
            />
          </Column>
        );
      }}
    />
  );
}
