import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import { fieldToTextField, TextFieldProps } from "formik-material-ui";
import { slug } from "../../helpers/utils";

export const SlugAutoCompleteField = (props: TextFieldProps) => (
  <MuiTextField
    {...fieldToTextField(props)}
    onBlur={event => {
      const { value } = event.target;
      const fieldName =
        props.inputProps && props.inputProps.field_name
          ? props.inputProps.field_name
          : "slug";

      if (!props.form.values[fieldName]) {
        props.form.setFieldValue(fieldName, value ? slug(value, true) : "");
      }
    }}
  />
);
