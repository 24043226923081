import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import { fieldToTextField, TextFieldProps } from "formik-material-ui";
import { slug } from "../../helpers/utils";

export const SlugField = (props: TextFieldProps) => (
  <MuiTextField
    {...fieldToTextField(props)}
    onChange={event => {
      const { value } = event.target;
      props.form.setFieldValue(
        props.field.name,
        value ? slug(value, false) : ""
      );
    }}
  />
);
