import React from "react";
import { Repeater } from "../../components/Repeater";
import { Paper } from "../../components/Paper";
import { Row } from "../../helpers/layout";
import { ImageField } from "./ImageField";
import { EditorField } from "./EditorField";

export const ColumnsField = React.memo(function(props: { name: string }) {
  return (
    <Repeater
      name={`${props.name}.columns`}
      wrapper={Paper}
      enableReordering
      addNewLabel={"New Column"}
      generateNewObject={() => {
        return {
          text: null,
          image: null
        };
      }}
    >
      {({ idx }) => {
        return (
          <Row>
            <EditorField
              name={`${props.name}.columns[${idx}].text`}
              label="Text"
            />
            <ImageField
              name={`${props.name}.columns[${idx}].image`}
              label="Image"
              related
            />
          </Row>
        );
      }}
    </Repeater>
  );
});
