import React from "react";
import PaperMUI from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 2)
  }
}));

export function Paper(props: { children: React.ReactNode }) {
  const classes = useStyles();

  return <PaperMUI className={classes.paper}>{props.children}</PaperMUI>;
}
