import React from "react";
import { Spacing } from "../helpers/layout";
import Typography from "@material-ui/core/Typography";
import { css } from "styled-components";

export const Error = (props: { text: string }) => {
  return (
    <div
      css={css`
        margin-top: ${Spacing.m};
      `}
    >
      <Typography variant="body2" color="error" align="center">
        {props.text}
      </Typography>
    </div>
  );
};
