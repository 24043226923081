import React from "react";
import * as Formik from "formik";
import * as Yup from "yup";
import { EditorField } from "./fields/EditorField";

export type FormValues = {
  text: any;
};

export function TextForm(props: {
  formRef: React.MutableRefObject<any>;
  initialValue: FormValues | null;
  onSubmit: (values: FormValues) => void;
}) {
  return (
    <Formik.Formik<FormValues>
      ref={props.formRef}
      initialValues={
        props.initialValue || {
          text: {}
        }
      }
      validationSchema={Yup.object().shape({
        text: Yup.object().required()
      })}
      onSubmit={(values: any) => {
        props.onSubmit(values);
      }}
    >
      {() => {
        return (
          <Formik.Form>
            <EditorField name={`text`} label="Text" simple />
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
