import React from "react";
import * as Formik from "formik";
import { Column } from "../../helpers/layout";
import { TextField } from "./TextField";
import { CheckboxWithLabel } from "formik-material-ui";

export function BlockFullscreenVideoField(props: { name: string }) {
  return (
    <Formik.FastField
      name={props.name}
      render={({ field }: Formik.FastFieldProps) => {
        return (
          <Column>
            <div>
              <Formik.FastField
                name={`${props.name}.mobile_id`}
                label="Mobile Cloudflare ID"
                type="text"
                fullWidth
                component={TextField}
                variant="outlined"
              />
            </div>
            <div>
              <Formik.FastField
                name={`${props.name}.tablet_id`}
                label="Tablet Cloudflare ID"
                type="text"
                fullWidth
                component={TextField}
                variant="outlined"
              />
            </div>
            <div>
              <Formik.FastField
                name={`${props.name}.desktop_id`}
                label="Desktop Cloudflare ID"
                type="text"
                fullWidth
                component={TextField}
                variant="outlined"
              />
            </div>
            <div>
              <Formik.FastField
                name={`${field.name}.has_sound`}
                Label={{ label: "Has sound?" }}
                component={CheckboxWithLabel}
              />
            </div>
          </Column>
        );
      }}
    />
  );
}
