import React from "react";
import { Column, Row } from "../../helpers/layout";
import { Repeater } from "../../components/Repeater";
import { ImageField } from "./ImageField";
import { SelectField } from "./SelectField";
import { paddingOptions } from "../../helpers/form";
import styled from "styled-components/macro";

const FieldWrapper = styled.div`
  flex: 1 0 10%;
`;

export const BlockImageGridField = React.memo(function(props: {
  name: string;
}) {
  return (
    <Column>
      <Row>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding top"
            name={`${props.name}.padding.top`}
            options={paddingOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding bottom"
            name={`${props.name}.padding.bottom`}
            options={paddingOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding left"
            name={`${props.name}.padding.left`}
            options={paddingOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding right"
            name={`${props.name}.padding.right`}
            options={paddingOptions}
          />
        </FieldWrapper>
      </Row>
      <SelectField
        label="Grid layout"
        name={`${props.name}.layout`}
        fullWidth
        options={[
          {
            value: "logos",
            label: "Logos"
          },
          {
            value: "columns",
            label: "Columns"
          },
          {
            value: "masonry",
            label: "Masonry"
          }
        ]}
      />
      <Repeater
        name={`${props.name}.images`}
        enableReordering
        addNewLabel={"New Image"}
        generateNewObject={() => {
          return {
            name: "",
            columns: []
          };
        }}
      >
        {({ idx }) => {
          return (
            <ImageField
              name={`${props.name}.images[${idx}].image`}
              label="Image"
              related
            />
          );
        }}
      </Repeater>
    </Column>
  );
});
