import { formatISO } from "date-fns";
import limax from "limax";
import React from "react";

export function sanitizePrice(number: number) {
  if (number) {
    return number.toFixed(2);
  }

  return "0.00";
}

export function sanitizeInput(input: string | number, precision?: number) {
  if (!input) return "";

  const string = input.toString();

  if (string.match(/\.$/)) {
    return string;
  }

  const number = Number(input) || 0;

  if (precision) {
    const newNumber = roundToPrecision(number, precision);

    return newNumber.toString();
  } else {
    return number.toFixed(2);
  }
}

export function roundToPrecision(x: number, precision: number) {
  const y = x + precision / 2;

  return y - (y % precision);
}

export function serializeDate(value: string | null) {
  return value ? formatISO(new Date(value)) : null;
}

export function deserializeDate(value: string | null) {
  return value
    ? formatISO(new Date(value), {
        representation: "date",
      })
    : value;
}

export function slug(value: string, transform: boolean) {
  if (transform) {
    return limax(value);
  } else {
    return value.replace(/[^0-9a-zA-Z-_]/g, "");
  }
}

export const isImage = (mimeType: string) => {
  return ["image/jpeg", "image/png"].includes(mimeType);
};

export const nl2br = (text: string) => {
  return text.split(/\n/).map((item, i) => {
    return <p key={i}>{item}</p>;
  });
};
