import React from "react";
import * as Formik from "formik";
import { SelectField } from "./SelectField";
import { Column } from "../../helpers/layout";

export const paddingOptions = [
  {
    value: "none",
    label: "None"
  },
  {
    value: "l",
    label: "Small"
  },
  {
    value: "xl",
    label: "Medium"
  },
  {
    value: "xxl",
    label: "Large"
  },
  {
    value: "xxxl",
    label: "Extra Large"
  }
];

export function BlockSpacingField<T>(props: { name: string }) {
  return (
    <Formik.FastField
      name={props.name}
      render={() => {
        return (
          <Column>
            <SelectField
              fullWidth
              label="Spacing Size"
              name={`${props.name}.size`}
              options={paddingOptions}
            />
          </Column>
        );
      }}
    />
  );
}
