import React from "react";
import * as Formik from "formik";
import { SelectField } from "./SelectField";
import { Row, Column } from "../../helpers/layout";
import { EditorField } from "./EditorField";
import { TextField } from "./TextField";
import { paddingOptions, opacityOptions } from "../../helpers/form";
import Typography from "@material-ui/core/Typography";
import styled, { css } from "styled-components/macro";
import { ColorPickerField } from "./ColorPickerField";
import { CheckboxWithLabel } from "formik-material-ui";

const FieldWrapper = styled.div`
  flex: 1 0 10%;
`;

export function BlockVideoTextField<T>(props: { name: string }) {
  return (
    <Formik.FastField
      name={props.name}
      render={({ field }: Formik.FastFieldProps) => {
        return (
          <Column>
            <Row>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding top"
                  name={`${field.name}.padding.top`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding bottom"
                  name={`${field.name}.padding.bottom`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding left"
                  name={`${field.name}.padding.left`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding right"
                  name={`${field.name}.padding.right`}
                  options={paddingOptions}
                />
              </FieldWrapper>
            </Row>
            <Row>
              <FieldWrapper>
                <SelectField
                  label="Media Layout"
                  name={`${field.name}.layout`}
                  fullWidth
                  options={[
                    {
                      value: "fullsize",
                      label: "Fullsize",
                    },
                    {
                      value: "leftside",
                      label: "Leftside",
                    },
                    {
                      value: "rightside",
                      label: "Rightside",
                    },
                  ]}
                />
              </FieldWrapper>
              {(field.value.layout === "leftside" ||
                field.value.layout === "rightside") && (
                <FieldWrapper>
                  <SelectField
                    label="Media Size"
                    name={`${field.name}.media_size`}
                    fullWidth
                    options={[
                      {
                        value: "30",
                        label: "30%",
                      },
                      {
                        value: "50",
                        label: "50%",
                      },
                      {
                        value: "60",
                        label: "60%",
                      },
                    ]}
                  />
                </FieldWrapper>
              )}
              <FieldWrapper>
                <ColorPickerField
                  name={`${props.name}.bg_color`}
                  label="Background Color"
                />
              </FieldWrapper>
            </Row>
            <Row>
              <div
                css={css`
                  flex: 0 0 300px;
                `}
              >
                <Column>
                  <Typography variant="body2">Video</Typography>
                  <div>
                    <Formik.FastField
                      name={`${props.name}.mobile_id`}
                      label="Mobile Cloudflare ID"
                      type="text"
                      fullWidth
                      component={TextField}
                      variant="outlined"
                    />
                  </div>
                  <div>
                    <Formik.FastField
                      name={`${props.name}.tablet_id`}
                      label="Tablet Cloudflare ID"
                      type="text"
                      fullWidth
                      component={TextField}
                      variant="outlined"
                    />
                  </div>
                  <div>
                    <Formik.FastField
                      name={`${props.name}.desktop_id`}
                      label="Desktop Cloudflare ID"
                      type="text"
                      fullWidth
                      component={TextField}
                      variant="outlined"
                    />
                  </div>
                  <div>
                    <Formik.FastField
                      name={`${props.name}.has_sound`}
                      Label={{ label: "Has sound?" }}
                      component={CheckboxWithLabel}
                    />
                  </div>
                  <div>
                    <SelectField
                      label="Opacity"
                      name={`${field.name}.video_opacity`}
                      fullWidth
                      options={opacityOptions}
                    />
                  </div>
                </Column>
              </div>
              <div
                css={css`
                  flex: 1 0 30%;
                `}
              >
                <EditorField name={`${field.name}.text`} label="Text" />
              </div>
            </Row>
          </Column>
        );
      }}
    />
  );
}
