import React from "react";
import * as Formik from "formik";
import { Repeater } from "../../components/Repeater";
import { Paper } from "../../components/Paper";
import { Column, Row } from "../../helpers/layout";
import { TextField } from "./TextField";
import { ImageField } from "./ImageField";
import { css } from "styled-components/macro";
import { CheckboxWithLabel } from "formik-material-ui";

export const BlockLinksField = React.memo(function (props: { name: string }) {
  return (
    <Repeater
      name={`${props.name}.links`}
      wrapper={Paper}
      enableReordering
      addNewLabel={"New Link"}
      generateNewObject={() => {
        return {
          link: "",
          label: "",
          title: "",
          disabled: false,
          image: null,
        };
      }}
    >
      {({ idx }) => {
        return (
          <Row>
            <div
              css={css`
                flex: 0 0 200px;
              `}
            >
              <ImageField
                name={`${props.name}.links[${idx}].image`}
                label="Image"
                related
              />
            </div>
            <div
              css={css`
                flex: 1 0 10%;
              `}
            >
              <Column>
                <Formik.FastField
                  name={`${props.name}.links[${idx}].link`}
                  label="Link"
                  type="text"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
                <Formik.FastField
                  name={`${props.name}.links[${idx}].label`}
                  label="Label"
                  type="text"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
                <Formik.FastField
                  name={`${props.name}.links[${idx}].title`}
                  label="Title"
                  type="text"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
                <Formik.FastField
                  name={`${props.name}.links[${idx}].disabled`}
                  Label={{ label: "Disable?" }}
                  component={CheckboxWithLabel}
                />
              </Column>
            </div>
          </Row>
        );
      }}
    </Repeater>
  );
});
