import React from "react";
import * as Formik from "formik";
import { SelectField } from "./SelectField";
import { Row, Column } from "../../helpers/layout";
import { EditorField } from "./EditorField";
import { ImageField } from "./ImageField";
import { paddingOptions, opacityOptions } from "../../helpers/form";
import styled from "styled-components/macro";
import { ColorPickerField } from "./ColorPickerField";

const FieldWrapper = styled.div`
  flex: 1 0 10%;
`;

export function BlockImageTextField<T>(props: { name: string }) {
  return (
    <Formik.FastField
      name={props.name}
      render={({ field }: Formik.FastFieldProps) => {
        return (
          <Column>
            <Row>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding top"
                  name={`${field.name}.padding.top`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding bottom"
                  name={`${field.name}.padding.bottom`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding left"
                  name={`${field.name}.padding.left`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding right"
                  name={`${field.name}.padding.right`}
                  options={paddingOptions}
                />
              </FieldWrapper>
            </Row>
            <Row>
              <FieldWrapper>
                <SelectField
                  label="Media Layout"
                  name={`${field.name}.layout`}
                  fullWidth
                  options={[
                    {
                      value: "fullsize",
                      label: "Fullsize"
                    },
                    {
                      value: "leftside",
                      label: "Leftside"
                    },
                    {
                      value: "rightside",
                      label: "Rightside"
                    }
                  ]}
                />
              </FieldWrapper>
              {(field.value.layout === "leftside" ||
                field.value.layout === "rightside") && (
                <FieldWrapper>
                  <SelectField
                    label="Media Size"
                    name={`${field.name}.media_size`}
                    fullWidth
                    options={[
                      {
                        value: "30",
                        label: "30%"
                      },
                      {
                        value: "50",
                        label: "50%"
                      },
                      {
                        value: "60",
                        label: "60%"
                      }
                    ]}
                  />
                </FieldWrapper>
              )}
              <FieldWrapper>
                <ColorPickerField
                  name={`${props.name}.bg_color`}
                  label="Background Color"
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  label="Image Opacity"
                  name={`${props.name}.bg_opacity`}
                  fullWidth
                  options={opacityOptions}
                />
              </FieldWrapper>
            </Row>
            <Row>
              <ImageField name={`${field.name}.image`} label="Image" related />
              <EditorField name={`${field.name}.text`} label="Text" />
            </Row>
          </Column>
        );
      }}
    />
  );
}
