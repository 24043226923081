import styled from "styled-components";
import { css } from "styled-components/macro";

export enum Spacing {
  none = "0",
  xs = "0.25rem",
  s = "0.5rem",
  m = "1rem",
  l = "1.5rem",
  xl = "2rem",
  xxl = "4rem",
  xxxl = "8rem",
  xxxxl = "16rem"
}

export type Align = "flex-start" | "flex-end" | "space-between" | "center";

export const Column = styled.div<{
  align?: Align;
  justify?: Align;
  gutter?: Spacing;
  padding?: Spacing;
  separatedItems?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justify || "flex-start"};
  align-items: ${props => props.align || "flex-start"};
  padding: ${props => props.padding || Spacing.none};

  > * {
    width: 100%;
  }

  > * + * {
    margin-top: ${props => props.gutter || Spacing.m};
  }
`;

export const Row = styled.div<{
  align?: Align;
  justify?: Align;
  gutter?: Spacing;
  padding?: Spacing;
  width?: number | string;
  separatedItems?: boolean;
  wrap?: "wrap" | "nowrap";
}>`
  display: flex;
  flex-wrap: ${props => props.wrap || "nowrap"};
  justify-content: ${props => props.justify || "flex-start"};
  align-items: ${props => props.align || "flex-start"};
  padding: ${props => props.padding || Spacing.none};
  width: ${({ width }) => {
    if (width) {
      if (typeof width === "number") {
        if (width <= 1) {
          return width * 100 + "%";
        } else {
          return width + "px";
        }
      } else {
        return width;
      }
    }
    return undefined;
  }};

  ${props => {
    const gutter = props.gutter || Spacing.m;

    if (props.wrap) {
      return css`
        margin-bottom: ${"-" + gutter};
        margin-right: ${"-" + gutter};
        > * {
          margin-bottom: ${gutter};
          margin-right: ${gutter};
        }
      `;
    } else {
      return css`
        > * + * {
          margin-left: ${gutter};
        }
      `;
    }
  }};
`;
