import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { RouteChildrenProps } from "react-router";
import { Layout } from "../components/Layout";
import { Row } from "../helpers/layout";
import { Paper } from "../components/Paper";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../components/Breadcrumbs";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import SettingsIcon from "@material-ui/icons/Build";
import TuneIcon from "@material-ui/icons/Tune";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import WishlistIcon from "@material-ui/icons/Loyalty";
import PeopleIcon from "@material-ui/icons/People";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";

export function Other(props: RouteChildrenProps<{}>) {
  const routeOthers: {
    label: string;
    icon?: JSX.Element;
    link: string;
  }[] = [
    {
      label: "Announcements",
      icon: <NotificationImportantIcon />,
      link: "/announcements"
    },
    {
      label: "Rewarders",
      icon: <EmojiEventsIcon />,
      link: "/rewarders"
    },
    {
      label: "Product Options",
      icon: <TuneIcon />,
      link: "/product-options"
    },
    {
      label: "Product Tags",
      icon: <FindInPageIcon />,
      link: "/product-tags"
    },
    {
      label: "In Home Tags",
      icon: <ImageSearchIcon />,
      link: "/in-home-tags"
    },
    {
      label: "Post Tags",
      icon: <FindInPageIcon />,
      link: "/post-tags"
    },
    {
      label: "Help Texts",
      icon: <LiveHelpIcon />,
      link: "/help-texts"
    },
    {
      label: "Tax Rates",
      icon: <AttachMoneyIcon />,
      link: "/tax-rates"
    },
    {
      label: "Users",
      icon: <PeopleIcon />,
      link: "/users"
    },
    {
      label: "Wishlists",
      icon: <WishlistIcon />,
      link: "/wishlists"
    },
    {
      label: "Settings",
      icon: <SettingsIcon />,
      link: "/settings"
    }
  ];
  return (
    <Layout>
      <Breadcrumbs>
        <Typography color="textPrimary">Other</Typography>
      </Breadcrumbs>
      <Paper>
        <Row wrap="wrap">
          {routeOthers.map(o => {
            return (
              <Card key={`route-other-selector--${o.label}`}>
                <Link
                  to={o.link}
                  css={css`
                    text-decoration: none;
                    color: currentColor;
                  `}
                >
                  <div
                    css={css`
                      width: 150px;
                      height: 150px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      cursor: pointer;
                    `}
                  >
                    {o.icon && (
                      <span
                        css={css`
                          margin-bottom: 10px;
                        `}
                      >
                        {o.icon}
                      </span>
                    )}

                    {o.label}
                  </div>
                </Link>
              </Card>
            );
          })}
        </Row>
      </Paper>
    </Layout>
  );
}
