import React, { useState } from "react";
import * as Formik from "formik";
import { CanvasPreview } from "../../components/CanvasPreview";
import { CanvasEditorModal } from "../../modals/CanvasEditorModal";

export function BlockCanvasField<T>(props: { name: string }) {
  const [showEditor, setShowEditor] = useState(false);

  return (
    <Formik.Field
      name={props.name}
      render={({ field, form }: Formik.FieldProps) => {
        const value = {
          image: field.value.image,
          bg_color: field.value.bg_color,
          bg_opacity: field.value.bg_opacity,
          parallax: field.value.parallax || "",
          animation: field.value.animation || "",
          align: field.value.align || "top-left",
          padding: field.value.padding,
          components: field.value.components || []
        };

        return (
          <React.Fragment>
            <CanvasPreview
              canvas={value}
              onEdit={() => {
                setShowEditor(true);
              }}
            />
            <CanvasEditorModal
              open={showEditor}
              handleClose={() => {
                setShowEditor(false);
              }}
              initialValues={value}
              onSubmit={values => {
                form.setFieldValue(props.name, values);
              }}
            />
          </React.Fragment>
        );
      }}
    />
  );
}
