import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import { fieldToTextField, TextFieldProps } from "formik-material-ui";

export const TextField = (
  props: TextFieldProps & {
    onChange?: (
      value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
  }
) => (
  <MuiTextField
    {...fieldToTextField(props)}
    onChange={event => {
      const { value } = event.target;

      props.form.setFieldValue(props.field.name, value);

      if (props.onChange) {
        props.onChange(event);
      }
    }}
  />
);
