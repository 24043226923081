import React from "react";
import * as Formik from "formik";
import { Repeater } from "../../components/Repeater";
import { Paper } from "../../components/Paper";
import { Column, Row } from "../../helpers/layout";
import { TextField } from "./TextField";
import styled from "styled-components";
import { SelectField } from "./SelectField";
import { paddingOptions } from "../../helpers/form";
import { ImageField } from "./ImageField";

const FieldWrapper = styled.div`
  flex: 1 0 10%;
`;

export const BlockDesignerField = React.memo(function (props: {
  name: string;
}) {
  return (
    <Formik.FastField
      name={props.name}
      render={({ field }: Formik.FastFieldProps) => {
        return (
          <Column>
            <Row>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding top"
                  name={`${field.name}.padding.top`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding bottom"
                  name={`${field.name}.padding.bottom`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding left"
                  name={`${field.name}.padding.left`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding right"
                  name={`${field.name}.padding.right`}
                  options={paddingOptions}
                />
              </FieldWrapper>
            </Row>
            <Row>
              {" "}
              <FieldWrapper>
                <Formik.FastField
                  name={`${props.name}.title`}
                  label="Title"
                  type="text"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
              </FieldWrapper>{" "}
              <FieldWrapper>
                <Formik.FastField
                  name={`${props.name}.description`}
                  label="Description"
                  type="text"
                  fullWidth
                  multiline
                  rows="4"
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                />
              </FieldWrapper>
            </Row>
            <Repeater
              name={`${field.name}.designers`}
              wrapper={Paper}
              enableReordering
              addNewLabel={"New Designer"}
              generateNewObject={() => {
                return {
                  logo: null,
                  url: "",
                };
              }}
            >
              {({ idx }) => {
                return (
                  <Column>
                    <Formik.FastField
                      name={`${props.name}.designers[${idx}].url`}
                      label="Url"
                      type="text"
                      fullWidth
                      component={TextField}
                      margin="normal"
                      variant="outlined"
                    />
                    <ImageField
                      name={`${props.name}.designers[${idx}].logo`}
                      label="Logo"
                      related
                    />
                  </Column>
                );
              }}
            </Repeater>
          </Column>
        );
      }}
    />
  );
});
