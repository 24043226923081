export const paddingOptions = [
  {
    value: "none",
    label: "None"
  },
  {
    value: "l",
    label: "Small"
  },
  {
    value: "xl",
    label: "Medium"
  },
  {
    value: "xxl",
    label: "Large"
  },
  {
    value: "xxxl",
    label: "Extra Large"
  }
];

export const animationOptions = [
  {
    value: "",
    label: "None"
  },
  {
    value: "fadeIn",
    label: "Fade in"
  },
  {
    value: "fadeInLeft",
    label: "Fade in left"
  },
  {
    value: "fadeInDown",
    label: "Fade in Down"
  },
  {
    value: "fadeInRight",
    label: "Fade in right"
  },
  {
    value: "fadeInUp",
    label: "Fade in up"
  }
];

export const opacityOptions = [
  {
    value: "0",
    label: "0%"
  },
  {
    value: "0.1",
    label: "10%"
  },
  {
    value: "0.2",
    label: "20%"
  },
  {
    value: "0.3",
    label: "30%"
  },
  {
    value: "0.4",
    label: "40%"
  },
  {
    value: "0.5",
    label: "50%"
  },
  {
    value: "0.6",
    label: "60%"
  },
  {
    value: "0.7",
    label: "70%"
  },
  {
    value: "0.8",
    label: "80%"
  },
  {
    value: "0.9",
    label: "90%"
  },
  {
    value: "1",
    label: "100%"
  }
];
