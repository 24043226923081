import React from "react";
import * as Formik from "formik";
import { Repeater } from "../../components/Repeater";
import { Paper } from "../../components/Paper";
import { Column } from "../../helpers/layout";
import { TextField } from "./TextField";
import { EditorField } from "./EditorField";

const ColumnsField = React.memo(function(props: {
  name: string;
  tabIdx: number;
}) {
  return (
    <Repeater
      name={`${props.name}.tabs[${props.tabIdx}].columns`}
      wrapper={Paper}
      enableReordering
      addNewLabel={"New Tab Column"}
      generateNewObject={() => {
        return {
          title: "",
          text: ""
        };
      }}
    >
      {({ idx }) => {
        return (
          <Column>
            <Formik.FastField
              name={`${props.name}.tabs[${props.tabIdx}].columns[${idx}].title`}
              label="Column Title"
              type="text"
              fullWidth
              component={TextField}
              margin="normal"
              variant="outlined"
            />
            <EditorField
              name={`${props.name}.tabs[${props.tabIdx}].columns[${idx}].text`}
              label="Column Text"
            />
          </Column>
        );
      }}
    </Repeater>
  );
});

export const BlockTabsField = React.memo(function(props: { name: string }) {
  return (
    <Repeater
      name={`${props.name}.tabs`}
      wrapper={Paper}
      enableReordering
      addNewLabel={"New Tab"}
      generateNewObject={() => {
        return {
          name: "",
          columns: []
        };
      }}
    >
      {({ idx: tabIdx }) => {
        return (
          <Column>
            <Formik.FastField
              name={`${props.name}.tabs[${tabIdx}].name`}
              label="Tab Name"
              type="text"
              fullWidth
              component={TextField}
              margin="normal"
              variant="outlined"
            />
            <Formik.FastField
              name={`${props.name}.tabs[${tabIdx}].note`}
              label="Tab Note"
              type="text"
              fullWidth
              component={TextField}
              margin="normal"
              variant="outlined"
            />
            <ColumnsField name={props.name} tabIdx={tabIdx} />
          </Column>
        );
      }}
    </Repeater>
  );
});
