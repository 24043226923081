import React from "react";
import * as Formik from "formik";
import { EditorField } from "./EditorField";
import { Row, Column } from "../../helpers/layout";
import { SelectField } from "./SelectField";
import { paddingOptions } from "../../helpers/form";
import styled from "styled-components/macro";

const FieldWrapper = styled.div`
  flex: 1 0 10%;
`;

export function BlockTextField(props: { name: string }) {
  return (
    <Formik.FastField
      name={props.name}
      render={({ field }: Formik.FastFieldProps) => {
        return (
          <Column>
            <Row>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding top"
                  name={`${field.name}.padding.top`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding bottom"
                  name={`${field.name}.padding.bottom`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding left"
                  name={`${field.name}.padding.left`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding right"
                  name={`${field.name}.padding.right`}
                  options={paddingOptions}
                />
              </FieldWrapper>
            </Row>
            <EditorField name={`${field.name}.text`} label="Text" />
          </Column>
        );
      }}
    />
  );
}
