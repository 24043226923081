import React from "react";
import * as Formik from "formik";
import { SelectField } from "./SelectField";
import { Row, Column } from "../../helpers/layout";
import { TextField } from "./TextField";
import { paddingOptions } from "../../helpers/form";
import styled from "styled-components/macro";

const FieldWrapper = styled.div`
  flex: 1 0 10%;
`;

export function BlockEmbedVideoField<T>(props: { name: string }) {
  return (
    <Formik.FastField
      name={props.name}
      render={({ field }: Formik.FastFieldProps) => {
        return (
          <Column>
            <Row>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding top"
                  name={`${field.name}.padding.top`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding bottom"
                  name={`${field.name}.padding.bottom`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding left"
                  name={`${field.name}.padding.left`}
                  options={paddingOptions}
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  fullWidth
                  label="Padding right"
                  name={`${field.name}.padding.right`}
                  options={paddingOptions}
                />
              </FieldWrapper>
            </Row>
            <Column>
              <Formik.FastField
                name={`${field.name}.url`}
                label="Video url"
                type="text"
                fullWidth
                component={TextField}
                margin="normal"
                variant="outlined"
              />
              <Formik.FastField
                name={`${field.name}.subtitle`}
                label="Subtitle"
                type="text"
                fullWidth
                component={TextField}
                margin="normal"
                variant="outlined"
              />
              <Formik.FastField
                name={`${field.name}.title`}
                label="Title"
                type="text"
                fullWidth
                component={TextField}
                margin="normal"
                variant="outlined"
              />

              <Formik.FastField
                name={`${field.name}.description`}
                label="Description"
                type="text"
                multiline
                fullWidth
                rows="4"
                component={TextField}
                margin="normal"
                variant="outlined"
              />
              <Formik.FastField
                name={`${field.name}.duration`}
                label="Duration"
                type="text"
                fullWidth
                component={TextField}
                margin="normal"
                variant="outlined"
              />
            </Column>
          </Column>
        );
      }}
    />
  );
}
