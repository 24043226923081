import React from "react";
import Typography from "@material-ui/core/Typography";
import { Layout } from "../components/Layout";

export function Error() {
  return (
    <Layout>
      <Typography variant="h1" color="primary" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" color="primary">
        Oops. Looks like the page you're looking for no longer exists
      </Typography>
      <Typography variant="h6" color="primary">
        But we're here to bring you back to safety
      </Typography>
    </Layout>
  );
}
