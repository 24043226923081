import React from "react";
import { Column, Row } from "../../helpers/layout";
import * as Formik from "formik";
import { TextField } from "./TextField";
import { ImageField } from "./ImageField";
import { SelectField } from "./SelectField";
import { paddingOptions } from "../../helpers/form";
import styled from "styled-components/macro";

const FieldWrapper = styled.div`
  flex: 1 0 10%;
`;

export function NewsletterField<T>(props: { name: string }) {
  return (
    <Column>
      <Row>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding top"
            name={`${props.name}.padding.top`}
            options={paddingOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding bottom"
            name={`${props.name}.padding.bottom`}
            options={paddingOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding left"
            name={`${props.name}.padding.left`}
            options={paddingOptions}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            fullWidth
            label="Padding right"
            name={`${props.name}.padding.right`}
            options={paddingOptions}
          />
        </FieldWrapper>
      </Row>

      <SelectField
        fullWidth
        label="Position"
        name={`${props.name}.position`}
        options={[
          {
            value: "leftside",
            label: "Leftside"
          },
          {
            value: "middle",
            label: "Middle"
          },
          {
            value: "rightside",
            label: "Rightside"
          }
        ]}
      />
      <Formik.FastField
        name={`${props.name}.title`}
        label="Title"
        type="text"
        fullWidth
        component={TextField}
        margin="normal"
        variant="outlined"
      />
      <Formik.FastField
        name={`${props.name}.text`}
        label="Text"
        type="text"
        multiline
        fullWidth
        rows="4"
        component={TextField}
        margin="normal"
        variant="outlined"
      />
      <ImageField name={`${props.name}.image`} label="Image" related />
    </Column>
  );
}
