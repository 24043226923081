import React from "react";
import convert from "htmr";
import draftToHtml from "draftjs-to-html";
import { css } from "styled-components/macro";
import { Spacing } from "../helpers/layout";

export function getCurrentColor(text: any) {
  if (!text) return text;

  const jsonString = JSON.stringify(text);
  const match = jsonString.match(/color-rgb\(([0-9]*,[0-9]*,[0-9]*)\)/);

  if (match) {
    return `rgba(${match[1]})`;
  }

  return "rgba(0,0,0)";
}

const customEntityTransform = (
  options: {
    currentColor: string;
  },
  entity: any,
  text: any
) => {
  const { currentColor } = options;

  if (entity.type === "HR") {
    return `<hr style="display:block;width:100%;max-width:100px;height:1px;border:none;background:${currentColor};" />`;
  }

  if (entity.type === "SPACING") {
    return "<br />";
  }

  if (entity.type === "LINK") {
    const targetOption = entity.data.targetOption || "_self";
    const buttonLook = entity.data.buttonLook;

    return `<a href="${entity.data.url}" target="${targetOption}" class="${
      buttonLook ? "button" : "link"
    }">${text}</a>`;
  }
};

export function EditorContent(props: { text: any }) {
  const currentColor = getCurrentColor(props.text);

  return (
    <div
      css={css`
        a {
          pointer-events: none;
        }

        .button {
          display: inline-block;
          background: #000;
          color: #fff;
          padding: 0 ${Spacing.l};
          text-decoration: none;
          border: 1px solid #fff;

          span {
            line-height: 3.66;
          }
        }

        a:not(.button) {
          color: inherit;
        }

        p {
          margin-top: 0;
        }

        p[style*="text-align:right"] + hr,
        p[style*="text-align: right"] + hr {
          margin: 0 0 0 auto;
        }

        p[style*="text-align:center"] + hr,
        p[style*="text-align: center"] + hr {
          margin: 0 auto 0 auto;
        }

        p[style*="text-align:left"] + hr,
        p[style*="text-align: left"] + hr {
          margin: 0 auto 0 0;
        }
      `}
    >
      {convert(
        draftToHtml(
          props.text,
          null as any,
          false,
          customEntityTransform.bind(null, {
            currentColor,
          })
        )
      )}
    </div>
  );
}
