import React from "react";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classnames from "classnames";
import { useLayout } from "../context/LayoutContext";

const useStyles = makeStyles(theme => ({
  row: {
    display: "flex",
    maxWidth: "100vw"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh"
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  fakeToolbar: {
    ...theme.mixins.toolbar
  }
}));

export function Layout({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  const { isDrawerOpened } = useLayout();

  return (
    <div>
      <Header />
      <div className={classes.row}>
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: isDrawerOpened
          })}
        >
          <div className={classes.fakeToolbar} />
          {children}
        </div>
      </div>
    </div>
  );
}
