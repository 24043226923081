import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import classnames from "classnames";

// styles
const useStyles = makeStyles(theme => ({
  dotBase: {
    width: 5,
    height: 5,
    backgroundColor: theme.palette.text.hint,
    borderRadius: "50%",
    transition: theme.transitions.create("background-color")
  },
  dotLarge: {
    width: 8,
    height: 8
  }
}));

export function Dot({
  size,
  color
}: {
  size?: "large" | "small";
  color: "primary" | "secondary";
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      className={classnames(classes.dotBase, {
        [classes.dotLarge]: size === "large"
      })}
      style={{
        backgroundColor:
          color && theme.palette[color] && theme.palette[color].main
      }}
    />
  );
}
